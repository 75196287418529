import React, { useState } from "react";
import style from "./profile.module.scss";
import { FormHead, FormsControl, Buttons } from "./";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function PaymentDetails(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [data, setData] = useState(props.data);
  const history = useHistory();
  const changeHandler = (e, isReq) => {
    const { name, value } = e.target;
    setData(
      [...data].map((object) => {
        if (object.code === name) {
          return {
            ...object,
            value: value,
            error: value.length === 0 && isReq ? name : "",
          };
        } else return object;
      })
    );
  };
  const cancelButtonClick = () => {
    setData(props.data);
    props.cancelbtn();
  };
  const onSubmit = () => {
    let error = false;
    let formdata = {};
    data.forEach((element) => {
      if (element.error) {
        error = true;
      } else {
        formdata = {
          ...formdata,
          [element.code]: element.value,
        };
      }
    });
    if (!error) {
      if ("paypal_email" in formdata) {
        formdata = {
          ...formdata,
          paymentMethod: "PayPal"
        }
      }
      else if ("wise_email" in formdata) {
        formdata = {
          ...formdata,
          paymentMethod: "Wise"
        }
      }
      else if ("user_detail_nbank" in formdata) {
        formdata = {
          ...formdata,
          paymentMethod: "Bank Transfer"
        }
      }
      console.log("formdata::", formdata);

      const service = new ProfileService();
      service.UpdatePaymentDetails(formdata).then((res) => {
        if (res.status) {
          props.showNotify("success", "success", "profile." + res.data.message);
          props.cancelbtn();
          props.updateSuccess(data, props.stateKey);
          props.getData();
        } else {
          if (res.error.code === 1002) {
            history.push("/logou");
          } else {
            props.showNotify(
              "danger",
              "error",
              "validation.checkValueYouHaveSubmitted"
            );
          }
        }

        // props.cancelbtn();
      });
    }
  };
  const changeDropDown = (key, id) => {
    setData(
      [...data].map((object) => {
        if (object.code === id.code) {
          return {
            ...object,
            value: key,
          };
        } else return object;
      })
    );
  };
  return (
    <div className={`${currentTheme == "theme-dark"
      ? `${style.dark_mode_PaymentDetails}`
      : `${style.PaymentDetails}`
      } body-card`} style={{ width: "100%" }}>
      <FormHead
        title={props?.cardTitle}
        change={props.change}
        status={props.status}
        show={props.isEditable}
      />
      {
        (data?.length > 0) && data.map((forms, index) => {
          return (
            <FormsControl
              key={index}
              change={changeHandler}
              status={props.isEditable}
              data={forms}
              dropdownChange={changeDropDown}
            />
          );
        })
      }
      {
        !props.isEditable && (
          <Buttons cancel={cancelButtonClick} submitHandler={onSubmit} />
        )
      }
    </div >
  );
}

export default PaymentDetails;