// import { BASE_URL, API_KEY } from "../../configuration/apiconfig";
// import UserStore from "../../store/UserStore";
import API from "../../api/api";

export default class PayoutService {
  key = localStorage.getItem("apiKey");
  PayoutTiles = async () => {
    return await API.API.get("payout/payout_tiles")
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  PendingTable = async (start, length, order = "", dierection = "asc") => {
    return await API.API.get(
      "payout/pending_list?length=" +
      length +
      "&start=" +
      start +
      "&order=" +
      order +
      "&direction=" +
      dierection
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  RejectedTable = async (start, length, order = "", dierection = "asc") => {
    return await API.API.get(
      "payout/rejected_list?length=" +
      length +
      "&start=" +
      start +
      "&order=" +
      order +
      "&direction=" +
      dierection
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  ApprovedTable = async (start, length, order = "", dierection = "asc") => {
    return await API.API.get(
      "payout/approved_pending_list?length=" +
      length +
      "&start=" +
      start +
      "&order=" +
      order +
      "&direction=" +
      dierection
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  PaidTable = async (start, length, order = "", dierection = "asc") => {
    return await API.API.get(
      "payout/approved_paid_list?length=" +
      length +
      "&start=" +
      start +
      "&order=" +
      order +
      "&direction=" +
      dierection
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  CancelRequest = async (id) => {
    return await API.API.post("payout/payout_request_cancelation", id)
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  PayoutRequest = async (data = null) => {
    return await API.API.post("payout/payout_request", data)
      .then((res) => {
          if(res.status===200){
             return  res.data
          }
          else{
            return res;
          }

      })
      .catch((error) => {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
      });
  };

  // async PayoutTiles(){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/payout_tiles', {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //pending data
  // async PendingTable(start,length,order='',dierection='asc'){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/pending_list?length='+length+'&start='+start+'&order='+order+'&direction='+dierection, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //paid data

  // async RejectedTable(start,length,order='',dierection='asc'){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/rejected_list?length='+length+'&start='+start+'&order='+order+'&direction='+dierection, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  // //Approved data
  // async ApprovedTable(start,length,order='',dierection='asc'){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/approved_pending_list?length='+length+'&start='+start+'&order='+order+'&direction='+dierection, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //Paid data
  // async PaidTable(start,length,order='',dierection='asc'){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/approved_paid_list?length='+length+'&start='+start+'&order='+order+'&direction='+dierection, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       :this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //cancel the request
  // async CancelRequest(id){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/payout_request_cancelation', {
  //             method: 'POST',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             body : id
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //get modal data
  // async PayoutRequest(data=null){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'payout/payout_request', {
  //             method: 'POST',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'api-key'       :this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             body: data
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }
}
