import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../../../shared/Spinner'

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const mapStateToProps = (state) => {
    console.log("state====>>>",state);
    return { mlmPlan: state.ProjectConfig.mlmPlan }
};

const ManageProductRefComponent = ({ mlmPlan }) => {
    let _isMounted = false;
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        _isMounted =true
        localStorage.setItem('productRefLink', JSON.stringify(params))
        history.push({pathname: '/referral_register/' + params?.sponser_id})
        return () => _isMounted = false;
    }, [])   

    return (
        <>
            <Spinner />
            <div>Already have an account?<Link to="/login">Login</Link></div>
        </>
    )
}
export default withTranslation()(connect(mapStateToProps)(withRouter(ManageProductRefComponent)));