import React from "react";
import { Form } from "react-bootstrap";
import style from "./registration.module.scss";
import { Paneltilte, Buttons } from "./";
import FormsControl from "./FormsControl";
// import {useTranslation} from 'react-i18next';
import { useSelector } from "react-redux";

function LoginInformation(props) {
  // const {t} = useTranslation()
  //change handler

  const { currentTheme } = useSelector((state) => state.Theme);
  const changehandler = (e) => {
    props.ChangeHandler(e, "LoginInfo");
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_SponsorPackage}`
          : `${style.SponsorPackage}`
      } card-bg-color`}
    >
      <Paneltilte tilte="loginInformation" />
      <Form
        onSubmit={(e) => {
          props.submit(e, "LoginInfo");
        }}
      >
        {props.data.map((field, index) => {
          return (
            <FormsControl
              key={index}
              data={field}
              change={changehandler}
              policy={props.policy}
              validation={field.validation}
              blurhandler={props.blurhandler}
              openModal={props.openModal}
            />
          );
        })}
        <Buttons
          prev={true}
          prevButtonClick={props.prevButtonClick}
          next={props.enableFinish}
        />
      </Form>
    </div>
  );
}

export default LoginInformation;
