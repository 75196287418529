import React from "react";
import style from "./cart.module.scss";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AlertBs from "../common/AlertBs";
import CartTable from "./CartTable";
import Nophoto from "../../../assets/images/nophoto/no_photo.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Links from "../dashboard/Links";

const CC_CartContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const currency = useSelector((state) => state.curr);
  const username = useSelector((state) => state.ProjectConfig.userName);

  const viewPackage = (id) => {
    history.push(`/package_details_cc/${id}`);
  };

  const cats = props.data.reduce((catsSoFar, item) => {
    const { amount, amazon_link, category, id, image, prod_id, product_name } =
      item;
    if (!catsSoFar[category]) catsSoFar[category] = [];
    catsSoFar[category].push({
      amount,
      amazon_link,
      category,
      id,
      image,
      prod_id,
      product_name,
    });
    return catsSoFar;
  }, {});

  const responsiveSettings = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const copyToClipboard = (sponsorId, productId) => {
    const referralLink = `http://user.cfcc-amp.com/referral_register/${sponsorId}/${productId}`;
    navigator.clipboard.writeText(referralLink).then(
      () => {
        // alert("Referral link copied to clipboard!");
      },
      () => {
        // alert("Failed to copy referral link.");
      }
    );
  };

  return (
    <div className={style.container}>
      <AlertBs
        varient={props.alert.varient}
        show={props.alert.show}
        message={props.alert.message}
        close={props.closeAlert}
      />
      {props.cart && props.cart.length > 0 && (
        <CartTable
          cart={props.cart}
          fromwhere="cashback_communtity"
          removeCart={props.removeCart}
        />
      )}

      {Object.keys(cats).map((key, index) => (
        <div key={index}>
          <div className={style.headingforallcards}>
            <b>{key.toUpperCase()}</b>
          </div>

          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            arrows={true}
            responsive={responsiveSettings}
            infinite={false}
            autoPlay={false}
            keyBoardControl={true}
            customTransition="transform 1.1s"
            transitionDuration={500}
            itemClass="carousel-item-padding-40-px"
          >
            {cats[key].map((item, idx) => (
              <Col key={idx}>
                <Card className="body-card">
                  <Card.Body className={style.panel}>
                    <div className="text-center">
                      <img
                        src={item.image || Nophoto}
                        className={style.cartItemImage}
                        alt={item.product_name || "No Image"}
                      />
                    </div>
                    <div className={style.itemTitle}>
                      <h4>{item.product_name}</h4>
                    </div>
                    <div className={style.textCenter}>
                      <span className={style.categoryTxt}>{item.category}</span>
                    </div>
                    <div className={style.textCenter}>
                      <small className={style.price}>
                        {currency.currentCurr}{" "}
                        {(item.amount * currency.value).toFixed(
                          currency.precision
                        )}
                      </small>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div
                        className="bg-dark d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: "20px",
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => copyToClipboard(`${username}`, item.id)}
                      >
                        <Links
                          iscopy={true}
                          icon="fa fa-files-o"
                          link={item.amazon_link}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer className={style.noPadder}>
                    <Row
                      className={`${style.noGutter} ${style.shopping_btn_manage}`}
                    >
                      <Col className={style.shopping_btn_manage_col}>
                        <div
                          className={`${
                            currentTheme === "theme-dark"
                              ? `${style.dark_mode_wrapper} text-center`
                              : `${style.wrapper} ${style.br} text-center`
                          }`}
                          style={{ border: "none" }}
                        >
                          <Button
                            variant="info"
                            className={`${style.btnAddon} ${style.btnAddonInfo}`}
                            onClick={() =>
                              props.addToCart(item.id, item.product_name)
                            }
                          >
                            <i className="fa fa-shopping-cart"></i>
                            {t("Button.addToCart")}
                          </Button>
                        </div>
                      </Col>

                      <Col className={style.shopping_btn_manage_col}>
                        <div
                          className={`${
                            currentTheme === "theme-dark"
                              ? `${style.dark_mode_wrapper} text-center`
                              : `${style.wrapper} ${style.br} text-center}`
                          }`}
                          style={{ border: "none" }}
                        >
                          <Button
                            onClick={() => viewPackage(item.id)}
                            variant="success"
                            className={`${style.btnAddon} btn-secondary`}
                          >
                            <i className="fa fa-eye"></i>
                            {t("Button.moreDetails")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Carousel>
        </div>
      ))}
    </div>
  );
};

export default CC_CartContent;
