import React from 'react';
import { Form } from 'react-bootstrap';
import style from './Common.module.scss';
import { useTranslation } from 'react-i18next';
import { IMG_URL } from '../../../configuration/apiconfig';
const DropDownBs = (props) => {
    const { t } = useTranslation();
    return (
        <Form.Group>
            {
                props.label &&
                <Form.Label className={props.required && style.required}>{props.label}</Form.Label>
            }
            <Form.Control
                as="select"
                className={`
                    ${style.dropdownField}
                    ${props.error && props.error.error && !props.textSuccess ? `${style.FormsHasError} form-control is-invalid` : ""}
                    body-form-element`}
                {...props.DropdownProps}
            >
                {
                    props.preOption &&
                    <option value="">
                        {props.preOption}
                    </option>
                }
                {
                    props.options.data &&
                    props.options.data.map((option, index) => (
                        // console.log(option),
                        <option
                            value={option[props.options.value]}
                            key={index}
                        >{option[props.options.label]}</option>
                    ))
                }
            </Form.Control>
            <Form.Text className={`
                ${props.error && props.error.error
                    ? (!props.textSuccess && style.isInvalid)
                    : "d-none"}
                `}>
                {
                    props.textIcon &&
                    [
                        props.textSuccess ?
                            <img src={`${IMG_URL}/public_html/images/accepted.png`} alt="" key="success" className={style.textSuccess} /> :
                            <i className="fa fa-times-circle" style={{ color: 'red' }} key="failiure"></i>
                    ]
                }
                &nbsp;
                {props.error && t(props.error.error, props.error.field)}
            </Form.Text>
        </Form.Group>
    );
}

export default DropDownBs;