import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import style from './registration.module.scss';
import { Paneltilte, Buttons } from './';
import FormsControl from './FormsControl';
import axios from 'axios';
import { BASE_URL, DEFAULT_KEY } from '../../../configuration/apiconfig';

function ContactInformation(props) {
    const [data, setData] = useState([])
    //change handler
    const changehandler = (e) => {
        props.ChangeHandler(e, 'contactInfo')
        console.log("changehandler::", e?.target?.value);
    }

    useEffect(() => {
        let newData = props.data.map(object => {
            if (object.code === 'gender') {
                let newOption = object.options.map((option) => {
                    return {
                        ...option,
                        code: ('profile.' + option.code)
                    }
                })
                return {
                    ...object,
                    options: newOption
                }
            } else {
                return object
            }
        });
        let addPaymentMethod = newData
        console.log("addPaymentMethod::", addPaymentMethod);
        setData(newData);
    }, [props?.data])

    useEffect(() => {
        console.log("addPaymentMethod::", data);
    }, [props?.data])

    return (
        <div className={`${style.SponsorPackage}`}>
            <Paneltilte
                tilte="contactInformation"
            />
            <Form onSubmit={(e) => { props.submit(e, 'contactInfo') }}>
                {
                    data.map((field, index) => {
                        return <FormsControl
                            key={index}
                            data={field}
                            change={changehandler}
                            refs={props.refs}
                            selectDate={props.selectDate}
                        />
                    })
                }
                <Buttons
                    prev={true}
                    prevButtonClick={props.prevButtonClick}
                />
            </Form>
        </div>
    )
}

export default ContactInformation
