import React, { useState } from 'react';
import { PersonalDetials, ContactDetails, BankDetails, PaymentDetails, Settings } from './';
import style from './profile.module.scss';
import AlertMessage from '../common/AlertMessage';
import { useTranslation } from 'react-i18next';
function EditContent(props) {
    console.log("editconent", props)

    // const myStaticData = [
    //     {
    //         "title": "PayPal Details",
    //         "code": "paypalDetails",
    //         "fields": [
    //             {
    //                 "title": "Paypal Email",
    //                 "code": "paypal_email",
    //                 "value": "paypal@gmail.com",
    //                 "type": "text",
    //                 "required": false
    //             }
    //         ]
    //     },
    //     {
    //         "title": "Wise Details",
    //         "code": "wiseDetails",
    //         "fields": [
    //             {
    //                 "title": "Wise Email",
    //                 "code": "wise_email",
    //                 "value": "wise@gmail.com",
    //                 "type": "text",
    //                 "required": false
    //             },
    //             {
    //                 "title": "Wise Currency",
    //                 "code": "wise_currency",
    //                 "value": "AUD",
    //                 "type": "select",
    //                 "required": false,
    //                 "options": [
    //                     {
    //                         "value": "AUD",
    //                         "title": "AUD",
    //                         "code": "AUD"
    //                     },
    //                     {
    //                         "value": "CAD",
    //                         "title": "CAD",
    //                         "code": "CAD"
    //                     },
    //                     {
    //                         "value": "EUR",
    //                         "title": "EUR",
    //                         "code": "EUR"
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         "title": "Bank Details",
    //         "code": "bankDetails",
    //         "fields": [
    //             {
    //                 "title": "Bank Name",
    //                 "code": "bank_name",
    //                 "value": "SBI",
    //                 "type": "text",
    //                 "required": false
    //             },
    //             {
    //                 "title": "Account Holder Name",
    //                 "code": "account_holder_name",
    //                 "value": "XYZ",
    //                 "type": "text",
    //                 "required": false
    //             },
    //             {
    //                 "title": "International Bank Account Number",
    //                 "code": "international_bank_account_number",
    //                 "value": "12345678901",
    //                 "type": "text",
    //                 "required": false
    //             },
    //             {
    //                 "title": "SWIFT BIC Code",
    //                 "code": "swift_bic_code",
    //                 "value": "123456",
    //                 "type": "text",
    //                 "required": false
    //             },
    //             {
    //                 "title": "Bank Routing Number",
    //                 "code": "bank_routing_number",
    //                 "value": "12345678",
    //                 "type": "text",
    //                 "required": false
    //             }
    //         ]
    //     }
    // ];
    const { t } = useTranslation()
    const [state, setState] = useState({
        EditPerosal: true,
        EditContact: true,
        EditBankDetails: true,
        EditPayPalDetails: true,
        EditWiseDetails: true,
        EditBanksDetails: true,
        EditSettingsDetails: true,
        loader: false
    });
    const notifyInintialState = {
        show: false,
        message: '',
        type: '',
        header: ''
    }
    const [notify, setNotify] = useState(notifyInintialState)
    const {
        EditPerosal,
        EditContact,
        EditBankDetails,
        EditPayPalDetails,
        EditWiseDetails,
        EditBanksDetails,
        EditSettingsDetails
    } = state
    const editButtonClick = (key) => {
        cancelbtn();
        setState(prev => ({
            ...prev,
            [key]: false
        }));

    }
    //cancel button click
    const cancelbtn = () => {
        Object.keys(state).forEach(i => {
            setState(prev => ({
                ...prev,
                [i]: true
            }));
        });
    }
    const notifyDissmissed = () => {
        setNotify(prev => ({
            ...prev,
            show: false
        }))
    }
    const showNotify = (type, header, message) => {
        setNotify(prev => ({
            ...prev,
            show: true,
            type: type,
            header: t('Common.' + header),
            message: t(message)
        }))
    }
    // cancelbtn();

    const [activeIndex, setActiveIndex] = useState(null);

    const handleEditClick = (index) => {
        setActiveIndex(index);
    };

    const handleCancelClick = () => {
        setActiveIndex(null); // Reset activeIndex when cancel button is clicked
    };

    return (
        <div className={style.EditContent}>
            <PersonalDetials
                status={'EditPerosal'}
                change={editButtonClick}
                isEditable={EditPerosal}
                cancelbtn={cancelbtn}
                data={props.editData.personal_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="personal_details"
                showNotify={showNotify}
                getData={props.getData}

            />
            <ContactDetails
                status={'EditContact'}
                change={editButtonClick}
                isEditable={EditContact}
                cancelbtn={cancelbtn}
                data={props.editData.contact_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="contact_details"
                showNotify={showNotify}
                getData={props.getData}
            />

            {/* <BankDetails 
                    status={'EditBankDetails'}
                    change={editButtonClick}
                    isEditable={EditBankDetails}
                    cancelbtn={cancelbtn}
                    data={props.editData.bank_details.fields}
                    updateSuccess={props.updateSuccess}
                    stateKey="bank_details"
                    showNotify={showNotify}
                    getData={props.getData}
                /> */}

            <div style={{ display: "grid", alignContent: "start", gap: "30px" }}>
                {props.editData?.payment_details?.fields &&
                    // props.editData?.payment_details?.fields[0]?.options &&  
                    <PaymentDetails
                        status={'EditPayPalDetails'}
                        change={editButtonClick}
                        isEditable={EditPayPalDetails}
                        cancelbtn={cancelbtn}
                        data={props.editData.payment_details?.fields[0].fields}
                        // data={myStaticData[0]?.fields}
                        updateSuccess={props.updateSuccess}
                        stateKey="payment_details"
                        showNotify={showNotify}
                        getData={props.getData}
                        cardTitle={props.editData.payment_details?.fields[0].title}
                    />
                }
                {props.editData?.payment_details?.fields &&
                    // props.editData?.payment_details?.fields[0]?.options &&  
                    <PaymentDetails
                        status={'EditWiseDetails'}
                        change={editButtonClick}
                        isEditable={EditWiseDetails}
                        cancelbtn={cancelbtn}
                        data={props.editData.payment_details?.fields[1].fields}
                        // data={myStaticData[1]?.fields}
                        updateSuccess={props.updateSuccess}
                        stateKey="payment_details"
                        showNotify={showNotify}
                        getData={props.getData}
                        cardTitle={props.editData.payment_details?.fields[1].title}
                    />
                }
            </div>
            <div>
                {props.editData?.payment_details?.fields &&
                    // props.editData?.payment_details?.fields[0]?.options &&  
                    <PaymentDetails
                        status={'EditBanksDetails'}
                        change={editButtonClick}
                        isEditable={EditBanksDetails}
                        cancelbtn={cancelbtn}
                        data={props.editData.payment_details?.fields[2].fields}
                        // data={myStaticData[2]?.fields}
                        updateSuccess={props.updateSuccess}
                        stateKey="payment_details"
                        showNotify={showNotify}
                        getData={props.getData}
                        cardTitle={props.editData.payment_details?.fields[2].title}
                    />
                }
            </div>
            {props.editData.settings_details.fields &&
                <Settings
                    status={'EditSettingsDetails'}
                    change={editButtonClick}
                    isEditable={EditSettingsDetails}
                    cancelbtn={cancelbtn}
                    data={props.editData.settings_details.fields}
                    updateSuccess={props.updateSuccess}
                    stateKey="settings_details"
                    showNotify={showNotify}
                    getData={props.getData}
                />
            }
            {
                notify.show &&
                <AlertMessage
                    message={notify.message}
                    dismiss={notifyDissmissed}
                    type={notify.type}
                    header={notify.header}
                    show={notify.show}
                />
            }
        </div>
    )
}

export default EditContent
